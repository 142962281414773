// extracted by mini-css-extract-plugin
export var card = "Card-module--card--aSQXr";
export var cardWrapper = "Card-module--cardWrapper--nBxBa";
export var cardIllustrationWrapper = "Card-module--cardIllustrationWrapper--GLz9o";
export var cardIllustration = "Card-module--cardIllustration--aJfhh";
export var cat1 = "Card-module--cat1--N-3nN";
export var cat2 = "Card-module--cat2--7IKmx";
export var dog1 = "Card-module--dog1--htWft";
export var dog2 = "Card-module--dog2--xr91p";
export var dog3 = "Card-module--dog3--l10UL";
export var logo = "Card-module--logo--OCrn1";
export var visa = "Card-module--visa--sQ1oI";
export var chip = "Card-module--chip---A+Zl";
export var contactless = "Card-module--contactless--ndK3l";
export var heading = "Card-module--heading--7faqQ";
export var contentWrapper = "Card-module--contentWrapper--NSXKn";
export var content = "Card-module--content--ga1qY";
export var whatsappIcon = "Card-module--whatsappIcon--gT5Q1";
export var whatsappHeading = "Card-module--whatsappHeading--vSlXF";
export var messageWrapper = "Card-module--messageWrapper--Wtp4E";
export var firstMessageWrapper = "Card-module--firstMessageWrapper--EkYjY";
export var thirdMessageWrapper = "Card-module--thirdMessageWrapper--E++kE";
export var secondMessageWrapper = "Card-module--secondMessageWrapper--6RbZC";
export var fourthMessageWrapper = "Card-module--fourthMessageWrapper--L2csN";
export var buttonsWrapper = "Card-module--buttonsWrapper--qo5XG";
export var navigateButton = "Card-module--navigateButton--IIEpZ";
export var baiButton = "Card-module--baiButton--ALLBx";
export var pageStepAnimationCompleted = "Card-module--pageStepAnimationCompleted--kBzvB";
export var growWrapper = "Card-module--growWrapper--Vog8u";
export var shrinkWrapper = "Card-module--shrinkWrapper--n0oIc";
export var growCard = "Card-module--growCard--GR4QX";
export var shrinkCard = "Card-module--shrinkCard--aDrmt";
export var fadeIn = "Card-module--fadeIn--cxARN";
export var slideDown = "Card-module--slideDown--VDQ5-";
export var fadeSlideIn = "Card-module--fadeSlideIn--n82OM";