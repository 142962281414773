import cn from "classnames";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import React, { ReactElement, useEffect, useRef, useState } from "react";

import Button, { ButtonVariantName } from "../../../../../../atoms/button/Button";
import Heading from "../../../../../../atoms/heading/Heading";
import PageStepAnimation from "../../../../../../atoms/page-step-animation/PageStepAnimation";
import { useResult } from "../../../../../../organisms/result/use-cases/result-use-cases";
import { States } from "../../../../../../settings/state";
import { Events, track } from "../../../../../../utils/analytics";
import useTrackPageViewed from "../../../../../../utils/hooks/useTrackPageViewed";
import * as styles from "./Card.module.scss";

const explanation = [
  { key: "pg_card.message_explanation_1", className: styles.firstMessageWrapper },
  {
    key: "pg_card.message_explanation_2",
    className: styles.secondMessageWrapper,
  },
  { key: "pg_card.message_explanation_3", className: styles.thirdMessageWrapper },
  {
    key: "pg_card.message_explanation_4",
    className: styles.fourthMessageWrapper,
  },
];

const cat1: string = require("../../../images/pages/card/cat1.svg").default;
const cat2: string = require("../../../images/pages/card/cat2.svg").default;
const dog1: string = require("../../../images/pages/card/dog1.svg").default;
const dog2: string = require("../../../images/pages/card/dog2.svg").default;
const dog3: string = require("../../../images/pages/card/dog3.svg").default;
const logo: string = require("../../../images/pages/card/logo.svg").default;
const visa: string = require("../../../images/pages/card/visa.svg").default;
const chip: string = require("../../../images/pages/card/chip.svg").default;
const contactless: string = require("../../../images/pages/card/contactless.svg").default;

const Card = (): ReactElement => {
  const { t } = useTranslation();
  const result = useResult();
  const [whatsappStatus, setWhatsappStatus] = useState<string>("");
  const [pageStepAnimationCompleted, setPageStepAnimationCompleted] = useState<boolean>(false);
  const nextUrl = `/results/${result?.uuid}/card/name/`;
  const buttonsWrapperRef = useRef<HTMLDivElement>(null);

  const handleClick = (): void => {
    setWhatsappStatus(States.LOADING_STATE);
    track(Events.CLICKED_CTA, {
      eventSender: "Barkibu Card - Bai button",
      countryCode: result?.country,
      phone: result?.pet_parent_phone
        ? `+${result.pet_parent_country_code}${result.pet_parent_phone}`
        : "",
    });

    setTimeout(() => {
      setWhatsappStatus(States.SUCCESS_STATE);
    }, 2000);
  };

  const handleNavigate = (): void => {
    void navigate(nextUrl);
  };

  useTrackPageViewed(Events.CARD_VIEWED_BROWSER);

  useEffect(() => {
    const handleAnimationEnd = () => {
      track(Events.ANIMATION_END, {
        eventSender: "Barkibu Card - Animation ended",
      });
      buttonsWrapperRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const buttonsWrapperElement = buttonsWrapperRef.current;
    buttonsWrapperElement?.addEventListener("animationend", handleAnimationEnd);

    return () => {
      buttonsWrapperElement?.removeEventListener("animationend", handleAnimationEnd);
    };
  }, []);

  return (
    <>
      {result && (
        <PageStepAnimation
          adoptionClassName={styles.card}
          onAnimationComplete={() => setPageStepAnimationCompleted(true)}
        >
          <section
            className={cn(styles.cardWrapper, {
              [styles.pageStepAnimationCompleted]: pageStepAnimationCompleted,
            })}
          >
            <div
              className={cn(styles.cardIllustrationWrapper, {
                [styles.pageStepAnimationCompleted]: pageStepAnimationCompleted,
              })}
            >
              <div className={styles.cardIllustration}>
                <img src={cat1} alt={""} className={styles.cat1} />
                <img src={cat2} alt={""} className={styles.cat2} />
                <img src={dog1} alt={""} className={styles.dog1} />
                <img src={dog2} alt={""} className={styles.dog2} />
                <img src={dog3} alt={""} className={styles.dog3} />
                <img src={logo} alt={""} className={styles.logo} />
                <img src={visa} alt={""} className={styles.visa} />
                <img src={chip} alt={""} className={styles.chip} />
                <img src={contactless} alt={""} className={styles.contactless} />
              </div>
            </div>
            {whatsappStatus !== States.SUCCESS_STATE && (
              <Heading level={2} adoptionClassName={styles.heading}>
                {t("pg_card.title")}
              </Heading>
            )}
            <div className={styles.contentWrapper}>
              {whatsappStatus !== States.SUCCESS_STATE && (
                <>
                  <div className={styles.content}>
                    {explanation.map((message, index) => (
                      <div key={index} className={cn(styles.messageWrapper, message.className)}>
                        <p>{parse(t(message.key))}</p>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {whatsappStatus === States.SUCCESS_STATE && (
                <>
                  <svg className={cn("icon", `icon--whatsapp`, styles.whatsappIcon)} role="img">
                    <use href={`#icon-whatsapp`}></use>
                  </svg>
                  <Heading level={2} adoptionClassName={styles.whatsappHeading}>
                    {t("pg_card.bai_message_sent")}
                  </Heading>
                </>
              )}

              <div className={styles.buttonsWrapper} ref={buttonsWrapperRef}>
                <Button
                  type="button"
                  adoptionClassName={styles.navigateButton}
                  onClick={handleNavigate}
                  disabled={whatsappStatus === States.LOADING_STATE}
                >
                  {t("pg_card.continue_button")}
                </Button>
                {whatsappStatus !== States.SUCCESS_STATE && (
                  <Button
                    type="button"
                    variantName={ButtonVariantName.secondary}
                    adoptionClassName={styles.baiButton}
                    onClick={handleClick}
                    isLoading={whatsappStatus === States.LOADING_STATE}
                  >
                    {t("pg_card.bai_button")}
                  </Button>
                )}
              </div>
            </div>
          </section>
        </PageStepAnimation>
      )}
    </>
  );
};

export default Card;
