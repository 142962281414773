import { graphql } from "gatsby";
import { ReactElement } from "react";

import withFunnelWrapper from "../../../../features/quotes-funnel/ui/organisms/with-funnel-wrapper/with-funnel-wrapper";
import Card from "../../../../features/quotes-funnel/ui/pages/card/card/Card";
import { PageId } from "../../../../settings/pages";

const Page = (): ReactElement => <Card />;

export default withFunnelWrapper(Page, "pg-card", PageId.card);

export const query = graphql`
  query CardPageQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
